<template>
    <div class="order-item">
        <div class="order-item-image" v-if="orderItem.image">
            <img :src="orderItem.image" alt="#"/>
        </div>
        <div class="order-item-image" v-else>
            <img src="images/placeholder.png" alt="Placeholder Image"/>
        </div>

        <div class="order-item-info">
            <span class="title">{{ orderItem.product.name }}</span>

            <div class="order-item-prices">
            <span class="price">
                {{ orderItem.product.price.toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'}) }}
            </span>
                <span class="total-price">
                {{
                        (orderItem.product.price * orderItem.qty).toLocaleString('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                        })
                    }}
            </span>
            </div>
        </div>
        <div class="order-item-actions">
            <div class="quantity">
                <button @click="decrement">-</button>
                <input type="text" v-model="orderItem.qty">
                <button @click="increment">+</button>
            </div>

            <div class="remove" @click="removeFromCart">x</div>
        </div>
    </div>
</template>

<script setup>

import {inject} from "vue";

const props = defineProps({
    orderItem: {
        type: Object,
        required: true,
    },
});

const orderItems = inject('orderItems');

const removeFromCart = () => {
    let tempOrderItems = orderItems.value;
    if (tempOrderItems[props.orderItem.product.id].qty > 1) {
        tempOrderItems[props.orderItem.product.id].qty--;
    } else {
        delete tempOrderItems[props.orderItem.product.id];
    }

    orderItems.value = tempOrderItems;
}

</script>

<style lang="scss" scoped>
.order-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.2rem 0;

    &-image {
        width: 6.4rem;
        height: 6.4rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-prices {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 1.8rem;
            font-weight: 600;
            color: #000000;
        }
    }

    &-info {
        width: calc(100% - 6.4rem);
        display: flex;
        flex-flow: column wrap;
        row-gap: 1.6rem;
    }

    &-actions {
        padding-top: .8rem;
        display: flex;
        flex-flow: row wrap;
        column-gap: .8rem;
        width: 100%;
        justify-content: space-between;

        .quantity {
            display: flex;
            align-items: center;
            border-radius: .8rem;
            height: 4.8rem;
            overflow: hidden;

            input, button {
                border: none;
                height: 4.8rem;
            }

            input {
                width: 4.5rem;
                text-align: center;
                background-color: #EFEFF0;
            }

            button {
                position: relative;
                background-color: #EFEFF0;
                font-weight: 400;
                width: 4.8rem;
                height: 4.8rem;
                font-size: 2.2rem;

                &:after,
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 3.4rem;
                    background-color: #B9B9BC;
                }

                &:before {
                    right: auto;
                    left: 0;
                }

                &:first-of-type {
                    &:before {
                        display: none;
                    }
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .remove {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: .8rem;
            border: 1px solid #EFEFF0;
        }
    }
}
</style>
