<template>
    <main class="main">
        <slot/>
    </main>
</template>

<script setup>
import {localstorageRef} from "@/CustomRefs/localstorageRef.js";
import {provide} from "vue";

import BarryHeader from "@/Components/Barry/BarryHeader.vue";
import BarryFooter from "@/Components/Barry/BarryFooter.vue";

const orderItems = localstorageRef('orderItems', {});
provide('orderItems', orderItems);
</script>

<style lang="scss" scoped>

</style>
