<template>
    <aside class="order-list">
        <h2 class="list-header">
            Bestellijst
        </h2>
        <div class="order-list-items">
            <template v-for="item in orderItems">
                <order-list-item :order-item="item"/>
            </template>
        </div>

        <div class="order-list-total">
            <span>Totaal</span>
            <span>{{ calculateTotal() }}</span>
        </div>

        <div class="order-list-bottom" v-if="displayActions">
            <primary-button @click="openPaymentModal">Direct betalen +</primary-button>
            <secondary-button @click="addToCart">Toevoegen aan kaart +</secondary-button>
        </div>
    </aside>

    <modal :show="showModal" @close="closeModal">
        <h3>Betalen</h3>
        <p>Je staat op het punt om dit kaartje te betalen</p>
        <p>De totaalprijs is: €{{ order.total_price }}</p>

        <primary-button @click="placeOrder">
            Betalen
        </primary-button>

        <secondary-button @click="closeModal">
            Annuleren
        </secondary-button>
    </modal>
</template>

<script setup>

import PrimaryButton from "@/Components/general/PrimaryButton.vue";
import SecondaryButton from "@/Components/general/SecondaryButton.vue";
import OrderListItem from "@/Components/Barry/OrderListItem.vue";
import {inject, ref} from "vue";
import {router} from "@inertiajs/vue3";
import Modal from "@/Components/general/Modal.vue";

const orderItems = inject('orderItems');

const props = defineProps({
    displayActions: {
        type: Boolean,
        default: true,
        required: true,
    },
});

const calculateTotal = () => {
    if (orderItems.value) {
        return Object.values(orderItems.value).reduce((total, item) => {
            return total + item.product.price * item.qty;
        }, 0).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'});
    }
    return 0; // Return 0 if orderItems.value is empty
}

const showModal = ref(false);
const order = ref(null);

const addToCart = function () {
    router.post(
        route('cart.store'),
        {
            products: orderItems.value
        }
    )
}

const openPaymentModal = function () {
    axios.post(
        route('orders.preview'),
        {
            products: orderItems.value
        }
    ).then(response => {
        order.value = response.data;
        showModal.value = !showModal.value;
    })
}

const closeModal = function () {
    showModal.value = false;
}

const placeOrder = async () => {
    try {
        router.post(
            route('orders.store'),
            {
                products: orderItems.value
            }
        );

        orderItems.value = {};

        alert('Order is geplaatst!');

        showModal.value = false;
    } catch (error) {
        console.dir(error);

        alert('Oh no! Unable to place your order! :(');
    }
}
</script>

<style lang="scss" scoped>
.order-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 35rem;
    background: #FFFFFF;
    padding: 1.6rem;


    &-items {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;

        /** Stole this from here: https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ **/
        background:
            /* Shadow Cover TOP */
            linear-gradient(
                    white 30%,
                    rgba(255, 255, 255, 0)
            ) center top,

                /* Shadow Cover BOTTOM */
            linear-gradient(
                    rgba(255, 255, 255, 0),
                    white 70%
            ) center bottom,

                /* Shadow TOP */
            radial-gradient(
                    farthest-side at 50% 0,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
            ) center top,

                /* Shadow BOTTOM */
            radial-gradient(
                    farthest-side at 50% 100%,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
            ) center bottom;

        background-repeat: no-repeat;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-attachment: local, local, scroll, scroll;
    }

    &-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        padding: 1.6rem 0;
    }

    &-bottom {
        display: flex;
        flex-flow: row wrap;
        row-gap: 1.6rem;
        align-items: center;

        button {
            width: 100%;
        }
    }
}
</style>
